import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

class ScrollItem__WidgetLanding extends VScroll_Item {
  // overlay;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    // if (isMobile) {

    // }

    this.onShow = () => {
      // if (!isMobile) return;

      // this._click = () => this.click();

      // this.overlay = GetBy.id('sidebar-text');
      // this.overlay.addEventListener(Basics.clickEvent, this._call);
      // gsap.set(this.overlay, { opacity: 0 });

      // gsap.to(this.overlay, { opacity: 1, duration: .3, delay: .3, ease: Power2.easeOut });
      // this.timeId = setTimeout(() => { this._call(); }, 6000);
    };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  // click() {
  // clearTimeout(this.timeId);
  // this.overlay.removeEventListener(Basics.clickEvent, this._call);

  // gsap.to(this.overlay, { opacity: 0, duration: .3, ease: Power2.easeOut, onComplete: () => { this.overlay.remove(); } });



  // gsap.to(this.overlay, { opacity: 0, duration: .3, ease: Power2.easeOut });
  // }
}

Scroll._registerClass('widget-landing', ScrollItem__WidgetLanding);
