import { Mesh, Object3D, Vector3 } from "three";

import { Metrics } from "../core/Metrics";
import { Maths } from "../utils/Maths";

export default class WebGLObject extends Object3D {
  dom;
  size = new Vector3();
  rot = new Vector3();
  pos = new Vector3();
  mouse = new Vector3();
  mesh;
  material;
  geometry;

  constructor(opts = {}) {
    super();

    this.visible = false;

    this.geometry = opts.geometry ?? opts.geometry;
    this.material = opts.material ?? opts.material;
    this.dom = opts.dom ?? opts.dom;

    if (opts.size) {
      this.size.copy(opts.size);
    } else if (opts.src) {
      this.size.x = parseInt(opts.src.getAttribute('width'));
      this.size.y = parseInt(opts.src.getAttribute('height'));
    } else {
      const { width, height } = this.dom.getBoundingClientRect();
      this.size.x = width;
      this.size.y = height;
    }

    const defaults = {
      rotFactor: .9,
      xFactor: .9,
      yFactor: .9,
      zFactor: .9
    }

    this.opts = {
      ...defaults,
      ...opts
    };

    // this.lookAt = new Vector3(this.position);
    // this.lookAtdom = new Vector3(this.position);
  }

  get active() {
    return this.visible;
  }

  set active(value) {
    this.visible = value;
  }

  init() {
    this.mesh = new Mesh(this.geometry, this.material);
    this.add(this.mesh);

    if (this.opts.size) this.mesh.scale.set(this.opts.size.x, this.opts.size.y, this.opts.size.z);
  }

  update() {
    if (!this.visible) return;

    // this._lookAt.lerp(this._lookAtdom, .1);
    // this.lookAt(this._lookAt);

    this.position.x = Maths.lerp(this.position.x, this.pos.x - this.mouse.x, this.opts.xFactor);
    this.position.y = Maths.lerp(this.position.y, this.pos.y - this.mouse.y, this.opts.yFactor);
    this.position.z = Maths.lerp(this.position.z, this.pos.z + this.mouse.z, this.opts.zFactor);

    this.rotation.x = Maths.lerp(this.rotation.x, this.rot.x, this.opts.rotFactor);
    this.rotation.y = Maths.lerp(this.rotation.y, this.rot.y, this.opts.rotFactor);
    this.rotation.z = Maths.lerp(this.rotation.z, this.rot.z, this.opts.rotFactor);
  }

  resize(x, y, z = 1) {
    this.mesh.scale.set(x, y, z);
  }

  domPositionTo3D(__x, __y) {
    const x = -1 * Metrics.WIDTH * 0.5 + __x;
    const y = Metrics.HEIGHT * 0.5 - __y;

    return {
      x,
      y,
      z: 0
    };
  }

  dispose() {
    this.remove(this.mesh);
    this.geometry.dispose();
    this.material.dispose();
  }
}
