import { Box3, Group, Mesh, Vector3 } from 'three';
import gsap, { Back, Power3 } from "gsap";

import WebGLObject from "../_app/cuchillo/3D/WebGLObject";
import { CENTER, PLANE_GEOMETRY, PLANE_MATERIAL, SCRABBLE_OBJ, SETTINGS } from './constants';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { isMobile } from '../_app/cuchillo/core/Basics';

export default class CharScrabble extends WebGLObject {
    group = new Group()

    pageUrl = '';

    isShow = false;

    isDragging = false;
    isMatching = false;
    isHover = false;
    fixed = false;

    letter;
    model;
    diffusion;
    normal;
    roughness;
    metalness;
    envMap;
    draggI = 0;

    opa = 0;

    positions = {
        dom: new Vector3(),
        drag: new Vector3(),
        fixed: new Vector3()
    }

    sizes = {
        x: 0,
        y: 0
    }

    set hover(__active) {
        this.isHover = true;

        this.plane.material.map = __active ? TEXTURES[this.letter + '-alt'] : TEXTURES[this.letter];
        this.plane.material.needsUpdate = true;

        this.material.map = __active ? TEXTURES['scrabble-alt'] : TEXTURES['scrabble'];
        this.material.needsUpdate = true;
    }

    constructor(opts = {}) {
        super(opts);

        this.add(this.group);

        this.letter = opts.letter;
        this.model = opts.model;
        this.diffusion = opts.diffusion;
        this.normal = opts.normal;
        this.roughness = opts.roughness;
        this.metalness = opts.metalness;
        this.envMap = opts.envMap;
        this.fixed = opts.fixed;
        this.draggI = opts.draggI;
    }

    init() {
        this.mesh = new Mesh(SCRABBLE_OBJ[0].geometry, this.material);

        this.group.add(this.mesh);

        this.mesh.material = this.material;

        this.size.copy(this.mesh.scale);

        this.plane = new Mesh(PLANE_GEOMETRY, PLANE_MATERIAL.clone());
        this.plane.material.map = TEXTURES[this.letter];
        this.plane.material.needsUpdate = true;
        this.group.add(this.plane);

        if (this.fixed) this.pageUrl = this.dom.dataset.url;

        this.resize();
    }

    show(__d = 0) {
        if (this.isShow) return;

        this.resize();

        gsap.killTweensOf(this.opa);
        gsap.to(this, {
            duration: .8,
            delay: __d,
            opa: 1,
            ease: Power3.easeOut
        });

        this.isShow = true;
    }

    hide(__d = 0) {
        if (!this.isShow) return;

        gsap.killTweensOf(this.opa);
        gsap.to(this, {
            duration: .4,
            delay: __d,
            opa: 0,
            ease: Power3.easeOut,
            onComplete: () => {
                this.isShow = false;
            }
        });
    }

    directHide() {
        this.isShow = false;
        this.opa = 0;
        this.plane.material.opacity = this.opa;
        this.mesh.material.opacity = this.opa;
    }

    startDrag() {
        this.isDragging = true;
    }

    endDrag() {
        this.isDragging = false;

        if (this.isMatching) {
            this.fixed = false;

            const link = document.createElement('a');
            link.href = this.pageUrl;
            document.body.appendChild(link);

            setTimeout(() => {
                link.click();
                document.body.removeChild(link);
            }, 550);
        }
    }

    updateDrag(positions) {
        const { x, y } = this.domPositionTo3D(positions.x, positions.y);

        this.positions.drag.x = x;
        this.positions.drag.y = y;
    }

    update() {
        if (!this.visible) return;
        if (!this.isShow) return;

        if (this.isHover) this.hover = false;

        let x1, y1, z1;

        const { left, width, top, height } = this.dom.getBoundingClientRect();
        let { x, y } = this.domPositionTo3D(left + width / 2, top + height / 2);
        this.positions.dom.x = x;
        this.positions.dom.y = y;

        if (this.isDragging) {
            this.hover = true;

            x1 = this.positions.drag.x + width / 2;
            y1 = this.positions.drag.y + height / 2;
            z1 = this.positions.drag.z;
        } else if (this.fixed) {
            x1 = this.positions.fixed.x;
            y1 = this.positions.fixed.y;
            z1 = this.positions.fixed.z;
        } else {
            x1 = this.positions.dom.x;
            y1 = this.positions.dom.y;
            z1 = this.positions.dom.z;
        }

        this.pos.x = x1;
        this.pos.y = y1;
        this.pos.z = z1;

        this.plane.material.opacity = this.opa;
        this.mesh.material.opacity = this.opa;

        super.update();
    }

    resize() {
        if (!this.visible) return;

        const { x, y, width, height } = this.dom.getBoundingClientRect();

        const factor = width / SETTINGS.letterSize;
        super.resize(factor * this.size.x, factor * this.size.y, factor * this.size.z);

        this.sizes.x = factor * this.size.x;
        this.sizes.y = factor * this.size.y;

        let bbox = new Box3().setFromObject(this.mesh);
        let size = bbox.getSize(CENTER);

        this.plane.scale.set(size.x, size.y);
        this.plane.position.z = size.z * .5 + 4;

        this.isDragging = false;
        this.isHover = false;
        this.hover = false;

        if (this.fixed) {
            let bottomRightX;
            let bottomRightY;

            if (isMobile) {
                bottomRightX = (Metrics.WIDTH / 2.75 + width * this.draggI * 1.2);
                bottomRightY = (-Metrics.HEIGHT / 2.75);
            } else {
                bottomRightX = (Metrics.WIDTH / 2.5 + width * this.draggI * 1.2);
                bottomRightY = (-Metrics.HEIGHT / 2.5);
            }

            this.pos.x = bottomRightX;
            this.position.x = bottomRightX;
            this.pos.y = bottomRightY;
            this.position.y = bottomRightY;

            this.positions.fixed.x = bottomRightX
            this.positions.fixed.y = bottomRightY

            this.positions.drag.z = 200;
        }
    }

    dispose() {
        this.mesh.material.dispose();
        this.mesh.geometry.dispose();

        this.group.remove(this.plane);
        this.group.remove(this.mesh);

        this.remove(this.group);

        this.plane.geometry.dispose();
        this.plane.material.dispose();
    }
}
