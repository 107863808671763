import { gsap, Power2 } from 'gsap';

import { Accessibility } from '../core/Accessibility';
import { GetBy } from '../core/Element';
import EventDispatcher from '../core/EventDispatcher';
import Keyboard, { KEYS } from '../core/Keyboard';

export default class Sidemenu {
  static container = GetBy.id('Sidemenu');

  static ON_SHOW = "onshow";
  static ON_SHOW_END = "onshowend";
  static ON_HIDE = "onhide";
  static ON_HIDE_END = "onhideend";

  static STATE_OPEN = "OPEN";
  static STATE_CLOSE = "CLOSE";

  static options = {
    container: this.container,
    isMain: false
  };

  _links;
  _state;

  static get isOpen() { return this._state === Sidemenu.STATE_OPEN; }
  static get state() { return this._state };
  static set state(__state) {
    if (this._state === __state) return;

    this._state = __state;

    if (this.isOpen) {
      Keyboard.add(KEYS.ESC, 'Sidemenu_ESC', () => { this.hide(); });
      Accessibility.trap(this.container);
      EventDispatcher.dispatchEvent(Sidemenu.ON_SHOW);
    } else {
      Keyboard.remove(KEYS.ESC, 'Sidemenu_ESC');
      Accessibility.removeTrap();
      EventDispatcher.dispatchEvent(Sidemenu.ON_HIDE);
    }
  }

  static init() {
    Sidemenu.directHide();

    this._links = Array.from(GetBy.class('__link', this.container));
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  static toggleState() {
    if (!this.isOpen) this.show();
    else this.hide();
  }

  static show(__d = 0) {
    this.container.style.visibility = 'visible';
    this.container.setAttribute('aria-expanded', 'true');
    this.state = Sidemenu.STATE_OPEN;
    this.show__effect();
  }

  // SHOW
  static show__effect(__d = 0) {
    gsap.set(this._links, { opacity: 0 });
    gsap.set(this.container, { alpha: 1 });

    this._links.map((item, i) => {
      gsap.to(item, {
        opacity: 1,
        duration: 0.2,
        delay: i * .1,
        ease: Power2.easeInOut
      });
    });

    gsap.delayedCall(.3 + 0.1 * this._links.length, () => { this.afterShow(); });
  }

  static afterShow() {
    EventDispatcher.dispatchEvent(Sidemenu.ON_SHOW_END);
    this.enableScroll();
  }

  // HIDE
  static hide(__d = 0) {
    this.disableScroll();
    this.state = Sidemenu.STATE_CLOSE;
    this.hide__effect();
  }

  static directHide() {
    this.disableScroll();
    gsap.set(this.container, { opacity: 0 });
    if (this._links) gsap.set(this._links, { opacity: 0 });
    this._state = Sidemenu.STATE_CLOSE;
    this.afterHide();
  }

  static hide__effect(__d = 0) {
    gsap.to(this._links, {
      opacity: 0,
      duration: 0.2,
      ease: Power2.easeInOut
    });

    gsap.set(this.container, {
      alpha: 0, delay: .3,
      onComplete: () => {
        this.afterHide();
      }
    });
  }

  static afterHide() {
    this.container.style.visibility = 'hidden';
    this.container.setAttribute('aria-expanded', 'false');
    EventDispatcher.dispatchEvent(Sidemenu.ON_HIDE_END);
  }

  static enableScroll() {
    if (this.engine && !this.engine.enabled) this.engine.enabled = true;
  }

  static disableScroll() {
    if (this.engine && this.engine.enabled) this.engine.enabled = false;
  }

  static loop() {
    if (this.engine && this.engine.enabled) this.engine.loop();
  }

  static resize() {
    if (this.engine && this.engine.enabled) this.engine.resize();
  }
}
