import { gsap } from 'gsap';

import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { isMobile } from '../_app/cuchillo/core/Basics';
import { Maths } from '../_app/cuchillo/utils/Maths';

class ScrollItem__SliderDefault extends VScroll_Item {
    _call;
    _slider;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        if (!isMobile) {
            this._slider = new SliderScroll(__link, {
                interaction: true,
                hasScrollbar: false,
                itemClass: ScrollItem__SliderImages__Item
            });
        }

        this._call = () => this.loop();
        this.onShow = () => {
            this.resize();
            gsap.ticker.add(this._call);
        };

        this.onHide = () => {
            gsap.ticker.remove(this._call);
        };
    }

    resize(w, h) {
        super.resize(w, h);
        if (this._slider) this._slider.resize();
    }

    loop() {
        if (this._slider) {
            this._slider.loop();
            let current = Maths.clamp(Math.floor(this._slider.total * this._slider.progress) + 1, 1, this._slider.total - 2);
            this.item.style.setProperty('--current', current);
        }
    }

    dispose() {
        this.hide();

        if (this._slider) this._slider.dispose();
        super.dispose();
    }
}

class ScrollItem__SliderImages__Item extends VScroll_Item {
    hasHiddenEnabled = false;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    //update () {
    /* if (this.progress > .5) {
       const p = Math.max(0, Maths.normalize(1, .5, this.progress));
       this._x = this._x + ((Metrics.WIDTH * .5) * p);
     }*/
    /* super.update();
  }

  mouseOver () { }
  mouseDown () { }
  mouseUp () { }

  show () {
    super.show();
  }

  hide () {
    super.hide();
  }*/
}

Scroll._registerClass('SliderDefault', ScrollItem__SliderDefault);
