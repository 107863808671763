import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { ScrollItem__Default } from './ScrollItem__Default';

class ScrollItem__WhatIDo extends ScrollItem__Default {
    jobs = [];
    currentJob = 0;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this.setUpJobs();
    }

    setUpJobs() {
        this.jobs = GetBy.class('__job', this.item);
        gsap.set(this.jobs, { opacity: 0 });
    }

    loop() {
        super.loop();

        if (this.jobs.length > 0) {
            const p = Maths.map(this.progressInside, -.2, 1, 0, 1)
            const current = Math.floor(Maths.lerp(0, this.jobs.length, p));

            if (this.currentJob !== current) {
                this.currentJob = current;

                for (let i = 0; i < this.jobs.length; i++) {
                    const item = this.jobs[i];
                    gsap.killTweensOf(item);

                    const opacity = Maths.clamp(Maths.normalize(current, current + 8, i), 0, 1);
                    gsap.to(item, { opacity, duration: .2 });
                }
            }
        }
    }
}

Scroll._registerClass('block-what-i-do', ScrollItem__WhatIDo);
