import gsap from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { ScrollItem__Default } from './ScrollItem__Default';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

class ScrollItem__Underline extends ScrollItem__Default {
    underlines = [];

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this.setUpUnderlines();
    }

    setUpUnderlines() {
        this.underlines = GetBy.class('__underline', this.item);
        gsap.set(this.underlines, { scaleX: 0 });
    }

    loop() {
        super.loop();

        if (isSmartphone) return

        if (this.underlines.length > 0) {
            for (let i = 0; i < this.underlines.length; i++) {
                const item = this.underlines[i];
                const p = this.progressInside - .25 * i
                const scale = Maths.clamp(p, 0, 1);
                gsap.set(item, { scaleX: scale });
            }
        }
    }
}

Scroll._registerClass('block-underline', ScrollItem__Underline);
