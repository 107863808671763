import Char from './Char';
import { SCRABBLE_MATERIAL } from './constants';
import Scene from './Scene';

export default class Word {
    chars = [];
    id;
    material;
    current = false;
    force = false;
    dom;

    set hover(__active) {
        this.material.map = __active ? TEXTURES['scrabble-alt'] : TEXTURES['scrabble'];
        this.material.needsUpdate = true;
    }

    constructor(__dom, __c) {
        this.dom = __dom;
        this.id = __dom.id;

        this.material = SCRABBLE_MATERIAL.clone();

        __c.map(char => {
            const material = this.material.clone();
            const letter = char.innerHTML.toLowerCase();
            const c = new Char({ dom: char, model: '/assets/models/scrabble-2.obj', material, letter, word: this });
            this.chars.push(c);
            Scene.registerInteractiveObject(c);
        });

        Scene.addWord(this);

        this.resize();
        this.loop();
    }

    show(__d = 0) {
        this.chars.map((char, i) => {
            char.init();
            char.active = true;
            char.show(__d + i * .05)
        });
    }

    hide(__d = 0) {
        this.chars.map((char, i) => {
            char.hide(__d + i * .02);
            Scene.removeInteractiveObject(char);
        });
    }

    directHide() {
        this.chars.map(char => { char.directHide() });
    }

    loop(__p = 0, __current = false) {
        if (this.current !== __current) {
            if (__current) {
                this.setHover(true);
            } else {
                this.setHover(false);
            }

            this.current = __current;
        }

        this.chars.map(c => c.update(__p));

        if (this.force) {
            this.force = false;

            if (!this.current) this.setHover(false);
        }
    }

    setHover(__hover) {
        this.hover = __hover;
        this.chars.map(c => c.hover = __hover);
    }

    forceHover() {
        if (this.force) return;

        this.force = true;
        this.setHover(true);
    }

    resize() {
        this.chars.map(c => c.resize());
    }

    dispose() {
        Scene.removeWord(this);
        this.chars.map(c => c.dispose());
    }
}
