import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Scene from '../3D/Scene';
import Default from './Default';
import { GetBy } from '../_app/cuchillo/core/Element';
import CharScrabble from '../3D/CharScrabble';
import { SCRABBLE_MATERIAL } from '../3D/constants';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Basics, isMobile } from '../_app/cuchillo/core/Basics';

export default class Home extends Default {
  letters = [];

  overlay;

  constructor() {
    super();

    Scene.initHome();

    this._call = () => this.raf();

    const letters = GetBy.class('__letter', this.container);
    let draggI = 0;

    for (let i = 0; i < letters.length; i++) {
      const char = letters[i];
      const letter = char.innerHTML.toLowerCase();
      const isDraggable = char.hasAttribute('data-draggable');
      const material = SCRABBLE_MATERIAL.clone();

      let draggableIndex = -1;

      if (isDraggable) {
        draggableIndex = draggI;
        draggI++;
      }

      let xFactor = .9;
      let yFactor = .9;

      if (isDraggable) {
        xFactor = .15;
        yFactor = .15;
      }

      const c = new CharScrabble({ dom: char, material, letter, fixed: isDraggable, draggI: draggableIndex, zFactor: .25, yFactor, xFactor });

      Scene.registerInteractiveObject(c);

      this.letters.push(c);
      Scene.add(c);

      c.resize();
      c.update();
    }

    this.overlay = GetBy.id('sidebar-text');

    if (!isMobile) return;

    this._click = () => this.click();
    this.overlay.addEventListener(Basics.clickEvent, this._click);
    // gsap.set(this.overlay, { opacity: 0 });
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Scene.start();
  }

  afterShow() {
    super.afterShow();
    gsap.ticker.add(this._call);
    gsap.to(GetBy.id('Letterbag'), { opacity: 1, duration: .3, ease: Power2.easeOut, onComplete: () => { GetBy.id('Letterbag').style.pointerEvents = 'initial'; } });

    this.letters.map((letter, i) => {
      letter.init();
      letter.active = true;
      letter.show(i * .05)
    });

    gsap.to(this.overlay, { opacity: 1, duration: .3, delay: .3, ease: Power2.easeOut });

    if (!isMobile) return;
    this.timeId = setTimeout(() => { this._click(); }, 6000);
  }


  click() {
    clearTimeout(this.timeId);
    this.overlay.removeEventListener(Basics.clickEvent, this._click);
    gsap.to(this.overlay, { opacity: 0, duration: .3, ease: Power2.easeOut, onComplete: () => { this.overlay.remove(); } });
  }


  beforeHide() {
    super.beforeHide();

    this.letters.map((letter, i) => { letter.hide(i * .01) });
    GetBy.id('Letterbag').style.pointerEvents = 'none';
    gsap.to(GetBy.id('Letterbag'), { opacity: 0, duration: .3, ease: Power2.easeOut });
  }

  afterHide() {
    gsap.ticker.remove(this._call);
    this.letters.map(l => {
      Scene.removeInteractiveObject(l);
      Scene.remove(l);
      l.dispose();
    });

    Scene.stop();
    super.afterHide();
  }

  raf() {
    this.letters.map(l => l.update());

    Scene.loop();
  }

  resize() {
    super.resize();
    Scene.resize();
    this.letters.map(l => l.resize());
  }
}

ControllerPage._addPage('home', Home);