import lottie from 'lottie-web';
import gsap, { Power3 } from 'gsap';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { OBJ_LOADER, SCRABBLE_MATERIAL, SCRABBLE_OBJ, TEXTURE_LOADER } from '../3D/constants';
import { isDebug } from '../_app/cuchillo/core/Basics';

export default class Preloader {
    static container;
    static overlay;
    static logoLayer;
    static logoContainer;
    static text;
    static textLayer;
    static lottieAnim;
    static texturesLoaded = false;
    static pageLoaded = false;
    static animationComplete = false;

    static init() {
        this.container = GetBy.id('Preloader');

        this.overlay = GetBy.class('overlay', this.container);
        for (let i = 0; i < this.overlay.length; i++) {
            const ov = this.overlay[i];
            ov.style.transformOrigin = 'center top';
        }

        this.loadLetters();
        this.initText();
        this.initLottie();
    }

    static initLottie() {
        if (isDebug) {
            this.animationComplete = true;
        } else {
            this.logoLayer = GetBy.class('__first', this.container)[0];
            this.textLayer = GetBy.class('__second', this.container)[0];
            this.logoContainer = GetBy.class('__container', this.logoLayer)[0];

            this.lottieAnim = lottie.loadAnimation({
                container: this.logoContainer,
                renderer: 'svg',
                loop: false,
                path: '/assets/json/logo.json'
            });

            this.lottieAnim.addEventListener('enterFrame', (e) => {
                if (this.lottieAnim.currentFrame / this.lottieAnim.totalFrames >= 0.5) {
                    this.lottieAnim.pause();
                    this.animationComplete = true;
                    this.lottieAnim.removeEventListener('enterFrame');
                    if (this.texturesLoaded && this.pageLoaded) this.close();
                }
            });
        }
    }

    static initText() {
        const text = GetBy.selector('.__text p', this.container);
        this.text = new SplitText(text, { type: 'lines', tag: 'span' }).lines;
        new SplitText(text, { type: 'lines', linesClass: 'line', tag: 'span' }).lines;
        gsap.set(this.text, { y: '120%', rotateZ: '5deg' });
    }

    static update(__progress) { }

    static hide(__call) {
        this.call = __call;
        this.pageLoaded = true;

        if (this.texturesLoaded && this.animationComplete) this.close();
    }

    static close(__call) {
        if (isDebug) {
            gsap.to(this.container, {
                duration: .5,
                opacity: 0,
                onComplete: () => {
                    this.container.style.display = 'none';
                    if (this.call) this.call();
                }
            });
        } else {
            const cb = () => {
                this.showText(0);
                this.hideText(4.5);

                for (let i = 0; i < this.overlay.length; i++) {
                    const ov = this.overlay[i];
                    gsap.to(ov, {
                        scaleY: 0,
                        delay: 4.5 + .1 * i,
                        duration: 1,
                        ease: Power3.easeInOut,
                        onComplete: () => {
                            if (this.call && i === this.overlay.length - 1) this.call();
                        }
                    });
                }

                gsap.to(this.container, {
                    duration: 0.1,
                    opacity: 0,
                    delay: 5.5,
                    onComplete: () => {
                        this.container.style.display = 'none';
                    }
                });
            }

            this.hideLogo(.5, cb);
        }
    }

    static hideLogo(__d = 0, cb = () => { }) {
        this.lottieAnim.play();
        this.lottieAnim.addEventListener('complete', (e) => {
            this.lottieAnim.removeEventListener('complete', e);

            gsap.to(this.logoLayer, {
                duration: .5,
                opacity: 0,
                delay: __d,
                ease: Power3.easeOut,
                onComplete: () => {
                    cb();
                }
            });
        });
    }

    static showText(__d = 0) {
        gsap.set(this.textLayer, { opacity: 1 });
        this.text.map((t, i) => {
            gsap.to(t, { y: 0, rotateZ: 0, duration: 1.2, delay: __d + i * .1, ease: Power3.easeOut });
        });
    }

    static hideText(__d = 0) {
        gsap.to(this.text, { y: '120%', duration: .7, delay: __d, ease: Power3.easeOut });
    }

    /****** LOADERS ******/
    static loadLetters() {
        const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'ñ', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        const promises = [];

        alphabet.map(a => {
            const promise = this.loadLetter(a).then(diffusion => {
                TEXTURES[a] = diffusion;
            });
            promises.push(promise);
            const promisealt = this.loadLetter(a + '-alt').then(diffusion => {
                TEXTURES[a + '-alt'] = diffusion;
            });
            promises.push(promisealt);
        });

        const scrabbleTexture = this.loadScrabbleTexture().then(diffusion => {
            SCRABBLE_MATERIAL.map = diffusion;
            SCRABBLE_MATERIAL.needsUpdate = true;
            TEXTURES['scrabble'] = diffusion;
        });
        promises.push(scrabbleTexture);
        const scrabbleTextureAlt = this.loadScrabbleTexture('-alt').then(diffusion => {
            TEXTURES['scrabble-alt'] = diffusion;
        });
        promises.push(scrabbleTextureAlt);

        const scrabbleObj = this.loadeScrabbleGeometry().then(object => {
            SCRABBLE_OBJ.push(object.children[0]);
        });
        promises.push(scrabbleObj);

        Promise.all(promises).then(() => {
            this.texturesLoaded = true;
            if (this.pageLoaded && this.animationComplete) this.close();
        });
    }

    static loadLetter(letter) {
        return new Promise((resolve, reject) => {
            TEXTURE_LOADER.load(
                `/assets/images/${letter}.png`,
                diffusion => {
                    resolve(diffusion);
                },
                undefined,
                error => {
                    console.error('An error happened', error);
                    reject(error);
                }
            );
        });
    }

    static loadScrabbleTexture(suffix = '') {
        return new Promise((resolve, reject) => {
            TEXTURE_LOADER.load(
                `/assets/images/scrabble-texture${suffix}.png`,
                diffusion => {
                    resolve(diffusion);
                },
                undefined,
                error => {
                    console.error('An error happened', error);
                    reject(error);
                }
            );
        });
    }

    static loadeScrabbleGeometry() {
        return new Promise((resolve, reject) => {
            OBJ_LOADER.load(
                `/assets/models/scrabble-2.obj`,
                object => {
                    resolve(object);
                },
                undefined,
                error => {
                    console.error('An error happened', error);
                    reject(error);
                }
            );
        });
    }
}
