import _Sidemenu from '../_app/cuchillo/layout/Sidemenu';

export default class Sidemenu extends _Sidemenu {
  static show() {
    super.show();

    document.body.classList.add('--sidemenu');
  }

  static hide(__call) {
    super.hide();
    document.body.classList.remove('--sidemenu');
  }
}
