import { Box3, Color, DoubleSide, Group, Mesh } from 'three';
import gsap, { Back, Power3 } from "gsap";

import WebGLObject from "../_app/cuchillo/3D/WebGLObject";
import { BASIC_MATERIAL, CENTER, LETTER_PLANE, OBJ_LOADER, PLANE_GEOMETRY, PLANE_MATERIAL, SCRABBLE_OBJ, SETTINGS, TEXTURE_LOADER } from './constants';

export default class Char extends WebGLObject {
    group = new Group()

    isShow = false;
    force = false;
    current = false;

    letter;
    model;
    diffusion;
    normal;
    roughness;
    metalness;
    envMap;
    word;

    opa = 0;

    set hover(__active) {
        this.plane.material.map = __active ? TEXTURES[this.letter + '-alt'] : TEXTURES[this.letter];
        this.plane.material.needsUpdate = true;

        this.material.map = __active ? TEXTURES['scrabble-alt'] : TEXTURES['scrabble'];
        this.material.needsUpdate = true;
    }

    constructor(opts = {}) {
        super(opts);

        this.add(this.group);

        this.letter = opts.letter;
        this.model = opts.model;
        this.diffusion = opts.diffusion;
        this.normal = opts.normal;
        this.roughness = opts.roughness;
        this.metalness = opts.metalness;
        this.envMap = opts.envMap;
        this.word = opts.word;
    }

    init() {
        this.mesh = new Mesh(SCRABBLE_OBJ[0].geometry, this.material);

        this.group.add(this.mesh);

        this.mesh.material = this.material;

        this.size.copy(this.mesh.scale);

        this.plane = new Mesh(PLANE_GEOMETRY, PLANE_MATERIAL.clone());
        this.plane.material.map = TEXTURES[this.letter];
        this.plane.material.needsUpdate = true;
        this.group.add(this.plane);

        this.resize();
    }

    show(__d = 0) {
        if (this.isShow) return;

        this.resize();

        gsap.killTweensOf(this.opa);
        gsap.to(this, {
            duration: .5,
            delay: __d,
            opa: 1,
            ease: Power3.easeOut
        });

        this.isShow = true;
    }

    hide(__d = 0) {
        if (!this.isShow) return;

        gsap.killTweensOf(this.opa);
        gsap.to(this, {
            duration: .3,
            delay: __d,
            opa: 0,
            ease: Power3.easeOut,
            onComplete: () => {
                this.isShow = false;
            }
        });
    }

    directHide() {
        this.isShow = false;
        this.opa = 0;
        this.plane.material.opacity = this.opa;
        this.mesh.material.opacity = this.opa;
    }

    update(__p = 0) {
        if (!this.visible) return;
        if (!this.isShow) return;

        const { left, width, top, height } = this.dom.getBoundingClientRect();
        let { x, y } = this.domPositionTo3D(left + width / 2, top + height / 2);

        this.pos.x = x;
        this.pos.y = y;

        this.plane.material.opacity = __p * this.opa;
        this.material.opacity = __p * this.opa;

        this.plane.material.needsUpdate = true;
        this.material.needsUpdate = true;

        super.update();
    }

    resize() {
        if (!this.visible) return;

        const { x, y, width, height } = this.dom.getBoundingClientRect();

        const factor = width / SETTINGS.letterSize;
        super.resize(factor * this.size.x, factor * this.size.y, factor * this.size.z);

        let bbox = new Box3().setFromObject(this.mesh);
        let size = bbox.getSize(CENTER);

        this.plane.scale.set(size.x, size.y);
        this.plane.position.z = size.z * .5 + 4;
    }

    dispose() {
        this.mesh.material.dispose();
        this.mesh.geometry.dispose();

        this.group.remove(this.plane);
        this.group.remove(this.mesh);

        this.remove(this.group);

        this.plane.geometry.dispose();
        this.plane.material.dispose();
    }
}
