import { isDebug, isTouch } from '../_app/cuchillo/core/Basics';

import { SETTINGS, TEXTURE_LOADER } from './constants';
import ScrabbleSketch from './ScrabbleSketch';
import ScrabbleSketchHome from './ScrabbleSketchHome';
import ScrabbleSketchHomeMobile from './ScrabbleSketchHomeMobile';
import DebugPane from './DebugPane';

export default class Scene {
    _scene;
    _started = false;

    static get scene() {
        return this._scene.scene;
    }

    static get group() {
        return this._scene.group;
    }

    static init() {
        this._scene = new ScrabbleSketch({
            container: 'scene-three',
            debug: isDebug,
            transparent: true,
            clearColorAlpha: 0,
            camera2D: true,
            cameraPos: SETTINGS.cameraPos,
            controls: SETTINGS.controls
        });
    }

    static initHome() {
        if (isTouch) {
            this._scene = new ScrabbleSketchHomeMobile({
                container: 'scene-three',
                debug: isDebug,
                transparent: true,
                clearColorAlpha: 0,
                camera2D: true,
                cameraPos: SETTINGS.cameraPosHome,
                controls: SETTINGS.controls
            });
        } else {
            this._scene = new ScrabbleSketchHome({
                container: 'scene-three',
                debug: isDebug,
                transparent: true,
                clearColorAlpha: 0,
                camera2D: true,
                far: 5000,
                cameraPos: SETTINGS.cameraPosHome,
                controls: SETTINGS.controls
            });
        }
    }

    static dispose() {
        if (this._scene) this._scene.dispose();
    }

    static getCamera() {
        return this._scene.cameraGroup;
    }

    static setCameraPos(pos, lookAt) {
        this._scene.cameraPosition.copy(pos);
        if (lookAt) this._scene.cameraLookAtTarget.copy(lookAt);
    }

    static getCameraPos() {
        return this._scene.cameraGroup.position;
    }

    static start() {
        if (this._started) return;

        this._started = true;
        this._scene.start();
        // if (isDebug) DebugPane.init();
    }

    static stop() {
        this._scene.stop();
        this._started = false;
        // if (isDebug) DebugPane.dispose();
    }

    static loop() {
        if (!this._started) return;

        this._scene.loop();
    }

    static resize() {
        if (!this._started) return;

        this._scene.resize();
    }

    static getTime() {
        return this._scene.time;
    }

    static addWord(__w) {
        __w.chars.map(c => {
            this.group.add(c)
        });
    }

    static removeWord(__w) {
        __w.chars.map(c => {
            this.group.remove(c);
        });
    }

    static add(__c) {
        this.group.add(__c);
    }

    static remove(__c) {
        this.group.remove(__c);
    }

    static registerInteractiveObject(__obj) {
        this._scene.interactiveObjects.push(__obj);
    }

    static removeInteractiveObject(__obj) {
        this._scene.interactiveObjects = this._scene.interactiveObjects.filter(obj => obj !== __obj);
    }
}
