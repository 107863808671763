import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { isSmartphone } from '../_app/cuchillo/core/Basics';
import { ScrollItem__Default } from './ScrollItem__Default';

export class ScrollItem__VideoText extends ScrollItem__Default {
    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
    }

    // // setUpText() {
    // //     const text = GetBy.selector('.__text p', this.item);
    // //     if (text.length > 0 && !isSmartphone) {
    // //         this.text = new SplitText(text, { type: 'lines', linesClass: 'line', tag: 'span' }).lines;
    // //         gsap.set(this.text, { opacity: 0 });
    // //     }

    // //     const top = isMobile ? 140 : 120;
    // //     const title = GetBy.class('__title', this.item);
    // //     if (title.length > 0) {
    // //         this.title = new SplitText(title, { type: 'lines', tag: 'span' }).lines;
    // //         new SplitText(title, { type: 'lines', linesClass: 'line', tag: 'span' }).lines;
    // //         gsap.set(this.title, { y: top + '%', rotateZ: '5deg' });
    // //     }

    // //     const titleInner = GetBy.selector('.__title-inner p', this.item);
    // //     if (titleInner.length > 0) {
    // //         this.title = [...this.title, ...new SplitText(titleInner, { type: 'lines', tag: 'span' }).lines];
    // //         new SplitText(titleInner, { type: 'lines', linesClass: 'line', tag: 'span' }).lines;
    // //         gsap.set(this.title, { y: top + '%', rotateZ: '5deg' });
    // //     }

    // //     const textFade = GetBy.selector('.__text-fade p', this.item);
    // //     if (textFade.length > 0) {
    // //         this.textFade = new SplitText(textFade, { type: 'lines', tag: 'span' }).lines;
    // //         new SplitText(textFade, { type: 'lines', linesClass: 'line', tag: 'span' }).lines;
    // //         gsap.set(this.textFade, { opacity: 0 });
    // //     }

    // //     const list = GetBy.class('__list', this.item);
    // //     if (list.length > 0) {
    // //         this.list = list;
    // //         gsap.set(this.list, { opacity: 0 });
    // //     }

    // //     const numbers = GetBy.class('__number', this.item);
    // //     if (numbers.length > 0) {
    // //         this.numbers = numbers;
    // //     }
    // // }

    // showTitle() {
    //     this.title.map((t, i) => {
    //         gsap.to(t, { y: 0, rotateZ: 0, duration: 1, delay: i * .1, ease: Power3.easeOut });
    //     });

    //     this.textFade.map((t, i) => {
    //         gsap.to(t, { opacity: 1, duration: .8, delay: .2 + i * .1, ease: Power3.easeOut });
    //     });

    //     for (let i = 0; i < this.numbers.length; i++) {
    //         const num = this.numbers[i];
    //         const duration = num.innerHTML.split('').length;
    //         gsap.from(num, {
    //             textContent: 0,
    //             duration: Math.min(duration, 2),
    //             ease: Power2.easeOut,
    //             snap: { textContent: 1 }
    //         });
    //     }

    //     for (let i = 0; i < this.list.length; i++) {
    //         gsap.to(this.list[i], { opacity: 1, duration: .6, delay: .08 * i, ease: Power3.easeOut });
    //     }
    // }

    loop() {
        if (isSmartphone) return;

        if (this.text.length > 0) {
            const p = Maths.map(this.progressInside, -8, 8, 0, 1);

            console.log('PROGRESS', this.progressInside, p)
            const current = Math.floor(Maths.lerp(0, this.text.length, p));

            if (this.currentText !== current) {
                this.currentText = current;

                for (let i = 0; i < this.text.length; i++) {
                    const item = this.text[i];
                    gsap.killTweensOf(item);

                    const opacity = Maths.clamp(Maths.normalize(current, current + 8, i), 0, 1);
                    gsap.to(item, { opacity, duration: .2 });
                }
            }
        }
    }
}

Scroll._registerClass('block-video-text', ScrollItem__VideoText);
