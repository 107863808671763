import { Group, Raycaster, Vector2, Vector3 } from 'three';

import WebGLSketch from "../_app/cuchillo/3D/WebGLSketch";
import { Basics } from '../_app/cuchillo/core/Basics';

export default class ScrabbleSketchHomeMobile extends WebGLSketch {
    cameraPosition = new Vector3();
    group = new Group();
    mouse = new Vector2();
    raycaster = new Raycaster();
    interactiveObjects = [];
    isDragging = false;
    matchingLink = '';

    constructor(opts = {}) {
        super(opts);

        this.scene.add(this.group);

        this.group.position.x = -100;
    }

    start() {
        super.start();

        this._dragStart = (event) => this.handleDragStart(event);
        document.addEventListener(Basics.downEvent, this._dragStart);
    }

    stop() {
        super.stop();

        document.addEventListener(Basics.downEvent, this._dragStart);
    }

    handleDragStart(e) {
        const mouse = {
            x: (e.touches[0].pageX / window.innerWidth) * 2 - 1,
            y: -(e.touches[0].pageY / window.innerHeight) * 2 + 1
        };

        this.raycaster.setFromCamera(mouse, this.camera);
        const intersects = this.raycaster.intersectObjects(this.interactiveObjects);

        if (intersects.length > 0) {
            const object = intersects[0].object.parent.parent;

            if (object.fixed) {
                object.isMatching = true;
                object.endDrag();
            }
        }
    }

    update() {
        super.update();

        this.interactiveObjects.map(obj => {
            if (obj.fixed) obj.hover = true;
        });
    }

    resize() {
        this.domElement.style = "";
        const { width, height } = this.domElement.getBoundingClientRect();

        if (width === this.size.x && height === this.size.y) return;

        this.size.set(width, height);
        this.renderer.setSize(this.size.x, this.size.y);

        this.camera.aspect = this.size.x / this.size.y;
        this.camera.updateProjectionMatrix();
    }

    dispose() {
        super.dispose();

        document.body.style.cursor = 'default';
    }
}
