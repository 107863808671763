import gsap, { Power3 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { SliderHorizontalScroll } from '../_app/cuchillo/components/SliderHorizontalScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

export class ScrollItem__Clients extends VScroll_Item {
    text = [];
    currentText = 0;
    _slider;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this.setUpText();

        this._slider = new SliderHorizontalScroll(__link, {
            interaction: false,
            hasLimits: false,
            itemClass: SliderScroll__Horizontal__Item
        });

        this.onShow = () => { this.showTitle(); };
        this.onMove = () => { this.loop(); };
        this.onHide = () => { };
    }


    setUpText() {
        const title = GetBy.class('__title', this.item)[0];
        this.title = new SplitText(title, { type: 'lines', tag: 'span' }).lines;
        new SplitText(title, { type: 'lines', linesClass: 'line', tag: 'span' }).lines;
        gsap.set(this.title, { y: '120%', rotateZ: '5deg' });

        if (isSmartphone) return;

        const text = GetBy.selector('.__text p', this.item);
        this.text = new SplitText(text, { type: 'lines', charsClass: '--line', tag: 'span' }).lines;
        gsap.set(this.text, { opacity: 0 });
    }

    showTitle() {
        gsap.to(this.title, { y: 0, rotateZ: 0, duration: 1, ease: Power3.easeOut });
    }

    loop() {
        const p = 1 - this.progressInside;

        this._slider.progress = p;
        this._slider.loop();

        if (isSmartphone) return;

        const textP = Maths.map(p, 0, 1 / (this._slider.total + 1), 0, 1);
        const current = Math.floor(Maths.lerp(0, this.text.length, textP));

        if (this.currentText !== current) {
            this.currentText = current;

            for (let i = 0; i < this.text.length; i++) {
                const item = this.text[i];
                gsap.killTweensOf(item);

                const opacity = Maths.clamp(Maths.normalize(current, current + 5, i), 0, 1);
                gsap.to(item, { opacity, duration: .2 });
            }
        }
    }

    dispose() {
        this._slider.dispose();
        super.dispose();
    }
}

Scroll._registerClass('block-clients', ScrollItem__Clients);

class SliderScroll__Horizontal__Item extends VScroll_Item {
    hasHiddenEnabled = false;
    wrapper;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this.wrapper = GetBy.class('wrapper', __link)[0];

        this.onShow = () => { };
        this.onHide = () => { };
        this.onMove = () => {
            this.loop();
        };
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================
    loop() {
        if (isSmartphone || !this.wrapper) return;

        const p = Maths.map(this.progressInside, .2, 1, 0, 1);
        // if (p > 0) 
        gsap.set(this.wrapper, { y: (-1 * p * 130) + '%' });
    }
}
