import "core-js/stable";
import "regenerator-runtime/runtime";

import { Power2, gsap } from "gsap";

import { Scroll } from './_app/cuchillo/scroll/Scroll';
import { VSticky } from './_app/cuchillo/scroll/insiders/VSticky';
import { VScaleZero } from './_app/cuchillo/scroll/insiders/VScaleZero';
import { VScale } from './_app/cuchillo/scroll/insiders/VScale';
import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';
import { VDisplace } from './_app/cuchillo/scroll/insiders/VDisplace';
import { VInsiderMask } from './_app/cuchillo/scroll/insiders/VInsiderMask';

import { ScrollItem__Default } from './scroll/ScrollItem__Default';
import { ScrollItem__WhatIDo } from './scroll/ScrollItem__WhatIDo';
import { ScrollItem__HowIWork } from './scroll/ScrollItem__HowIWork';
import { ScrollItem__Clients } from './scroll/ScrollItem__Clients';
import { ScrollItem__Underline } from './scroll/ScrollItem__Underline';
import { ScrollItem__SliderDefault } from './scroll/ScrollItem__SliderDefault';
import { ScrollItem__SliderScrollbar } from './scroll/ScrollItem__SliderScrollbar';
// import { ScrollItem__ProjectList } from './scroll/ScrollItem__ProjectList';
import { ScrollItem__WidgetLanding } from './scroll/ScrollItem__WidgetLanding';
import { ScrollItem__VideoText } from './scroll/ScrollItem__VideoText';
import { ScrollItem__BillboardProject } from './scroll/ScrollItem__BillboardProject';

import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Basics, isDebug, isTouch } from './_app/cuchillo/core/Basics';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import { Interaction, MrInteraction } from './_app/cuchillo/core/Interaction';
import { GetBy } from "./_app/cuchillo/core/Element";
import { Metrics } from './_app/cuchillo/core/Metrics';
import { Statics } from './_app/cuchillo/utils/Statics';
import Keyboard from './_app/cuchillo/core/Keyboard';
import { ControllerWindow } from "./_app/cuchillo/windows/ControllerWindow";

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';

import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import Page from './_app/cuchillo/pages/Page';
import Default from './pages/Default';
import Home from './pages/Home';
import Projects from './pages/Projects';

import BG from './_app/cuchillo/layout/Background';
import Header from './layout/Header';
import Loading from './layout/Loading';
import Wrap from './layout/Wrap';
import Sidemenu from './layout/Sidemenu';

import Preloader from "./layout/Preloader";
import Language from "./_app/cuchillo/utils/Language";
import Scene from "./3D/Scene";

export default class Main {
  static stats;

  static init() {
    Basics.id = "w11p_v006"; // ID para cookies   
    Language.init();

    Header.init();
    // Scene.init();
    Sidemenu.init();

    Metrics.init(() => Main.resize()); // Tamaños y resize
    Keyboard.enable(); // ESC para cerrar ventana
    Accessibility.init(); // Utils accesibilidad
    Statics.init(); // Si estamos en debug pinta un FPS counter
    Interaction.init({ ajax: true }) // Posiciones del cursor (Movimiento, click...), Acciones links, drag...

    BG.init(CMS_COLORS); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page

    LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
    LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
    LoaderController.update = progress => Preloader.update(progress);
    LoaderController.onComplete = () => Main.setup();

    this.setWorker();

    GetBy.id('Letterbag').addEventListener('click', e => {
      e.stopPropagation();
    });

    // LOOP
    if (isDebug) {
      gsap.ticker.add(() => { Main.loopDebug(); });
    } else {
      gsap.ticker.add(() => { Main.loop(); });
    }

    Preloader.init();
    LoaderController.init();
  }

  static setup() {
    this.setupEvents();
    // INIT PAGE
    ControllerPage.init(Wrap.mainholder);
  }

  static setupEvents() {
    EventDispatcher.addEventListener(Page.ON_SHOW, () => {
      //Cursor.start();
      Loading.stop();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE, () => {
      //Cursor.hide();
      Sidemenu.hide();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE_END, () => {
      Loading.start();
    });

    // EventDispatcher.addEventListener(Win.ON_HIDE, () => { Scroll.setEnabled(true); });
    // EventDispatcher.addEventListener(Win.ON_SHOW, () => { Scroll.setEnabled(false); });
    EventDispatcher.addEventListener(Sidemenu.ON_HIDE, () => { Scroll.setEnabled(true); });
    EventDispatcher.addEventListener(Sidemenu.ON_SHOW, () => {
      Scroll.setEnabled(false);
      ControllerWindow.hideAll();
    });
  }

  static resize() {
    Header.resize();
    BG.resize();
    ControllerPage.resize();
    // Scene.resize();
    Sidemenu.resize();

    if (isTouch) GetBy.id('Main').style.height = `${Metrics.HEIGHT}px`;
  }

  static loop() {
    BG.loop();
    Header.loop();
    // Scene.loop();
    ControllerPage.loop();
    Sidemenu.loop();

    if (Scroll.isScrolling) Scroll.loop();
  }

  static loopDebug() {
    Statics.begin();
    this.loop();
    Statics.end();
  }

  static setWorker() {
    if ('serviceWorker' in navigator && !isDebug) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function () { });
    }
  }
}

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
  Main.init();
} else {
  document.addEventListener('DOMContentLoaded', Main.init);
}
