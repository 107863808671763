import { Group, Raycaster, Vector2, Vector3 } from 'three';

import WebGLSketch from "../_app/cuchillo/3D/WebGLSketch";
import { Basics, isSmartphone, isTouch } from '../_app/cuchillo/core/Basics';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { CENTER, SETTINGS } from './constants';

export default class ScrabbleSketchHome extends WebGLSketch {
    cameraPosition = new Vector3();
    group = new Group();
    mouse = new Vector2();
    raycaster = new Raycaster();
    interactiveObjects = [];
    isDragging = false;
    matchingLink = '';

    constructor(opts = {}) {
        super(opts);

        this.scene.add(this.group);

        this.group.position.x = -100;
    }

    start() {
        super.start();

        this._dragStart = (event) => this.handleDragStart(event);
        document.addEventListener(Basics.downEvent, this._dragStart);
        this._dragEnd = () => this.handleDragEnd();
        document.addEventListener(Basics.upEvent, this._dragEnd);
        this._dragMove = (event) => this.handleDragMove(event);
        document.addEventListener(Basics.moveEvent, this._dragMove);
    }

    stop() {
        super.stop();

        document.addEventListener(Basics.downEvent, this._dragStart);
        document.addEventListener(Basics.upEvent, this._dragEnd);
        document.addEventListener(Basics.moveEvent, this._dragMove);
    }

    handleInteractions() {
        if (this.isDragging) return;

        let cursor = 'default';

        const mouse = {
            x: (Interaction.positions.mouse.x / window.innerWidth) * 2 - 1,
            y: -(Interaction.positions.mouse.y / window.innerHeight) * 2 + 1
        };
        this.raycaster.setFromCamera(mouse, this.camera);
        const intersects = this.raycaster.intersectObjects(this.interactiveObjects);

        if (intersects.length > 0) {
            const object = intersects[0].object.parent.parent;

            if (object.fixed) {
                object.hover = true;
                cursor = 'grab';
            }
        }

        document.body.style.cursor = cursor;
    }

    handleDragStart(e) {
        this.isDragging = true;

        document.body.style.cursor = 'grabbing';

        const mouse = {
            x: (Interaction.positions.mouse.x / window.innerWidth) * 2 - 1,
            y: -(Interaction.positions.mouse.y / window.innerHeight) * 2 + 1
        };

        this.raycaster.setFromCamera(mouse, this.camera);
        const intersects = this.raycaster.intersectObjects(this.interactiveObjects);

        if (intersects.length > 0) {
            const object = intersects[0].object.parent.parent;

            if (object.fixed) {
                const positions = {
                    x: isTouch ? e.touches[0].screenX : e.clientX,
                    y: isTouch ? e.touches[0].screenY : e.clientY
                };
                object.updateDrag(positions);
                object.startDrag();
            }
        }
    }

    handleDragMove(e) {
        if (!this.isDragging) return;

        const positions = {
            x: isTouch ? e.touches[0].screenX : e.clientX,
            y: isTouch ? e.touches[0].screenY : e.clientY
        };

        this.interactiveObjects.map(obj => {
            if (obj.fixed && obj.isDragging) {
                const distanceX = obj.position.x - obj.positions.dom.x;
                const distanceY = obj.position.y - obj.positions.dom.y;

                if ((Math.abs(distanceX) + Math.abs(distanceY)) < 200) {
                    obj.isMatching = true;
                    this.matchingLink = obj.dom.dataset.draggable;
                } else {
                    obj.isMatching = false;
                    this.matchingLink = '';
                }

                obj.updateDrag(positions);
            }
        });
    }

    handleDragEnd() {
        this.isDragging = false;
        this.matchingLink = '';
        this.interactiveObjects.map(obj => { obj.endDrag(); });
    }

    checkMatching() {
        this.interactiveObjects.forEach(obj => {
            if (!obj.fixed) obj.hover = obj.dom.dataset[this.matchingLink] !== undefined;
        });
    }

    loop() {
        this.handleInteractions();
        super.loop();
    }

    update() {
        if (this.isDragging) this.checkMatching();

        super.update();
    }

    resize() {
        if (isSmartphone) return;

        this.domElement.style = "";
        const { width, height } = this.domElement.getBoundingClientRect();

        if (width === this.size.x && height === this.size.y) return;

        this.size.set(width, height);
        this.renderer.setSize(this.size.x, this.size.y);

        this.camera.aspect = this.size.x / this.size.y;
        this.camera.updateProjectionMatrix();
    }

    dispose() {
        super.dispose();

        document.body.style.cursor = 'default';
    }
}
