import gsap, { Power2, Power3 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

class ScrollItem__BillboardProject extends VScroll_Item {
  _bg;
  _shown = false;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.setUpText();
    this.setUpBG();

    this.onShow = () => {
      this.showBG();
      this.showTitle();
    };
    this.onMove = () => { this.loop(); };
    this.onHide = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================

  setUpBG() {
    this._bg = GetBy.selector('img', this.item)[0];
    gsap.set(this._bg, { opacity: 0 });
  }

  showBG() {
    gsap.to(this._bg, { opacity: 1, duration: 1.5, ease: Power2.easeOut, onComplete: () => { this._shown = true; } });
  }

  setUpText() {
    const title = GetBy.class('__title', this.item);
    this.title = new SplitText(title, { type: 'lines', tag: 'span' }).lines;
    new SplitText(title, { type: 'lines', linesClass: 'line', tag: 'span' }).lines;
    gsap.set(this.title, { y: '160%', rotateZ: '3deg' });
  }

  showTitle() {
    this.title.map((t, i) => {
      gsap.to(t, { y: 0, rotateZ: 0, duration: 1, delay: i * .1, ease: Power3.easeOut });
    });
  }

  loop() {
    if (!this._shown) return;

    let opacity = 1;
    if (this.progress >= .5) opacity = Maths.clamp(Maths.map(this.progress, .5, .9, 1, 0), 0, 1);

    gsap.set(this._bg, { opacity });
  }
}

Scroll._registerClass('billboard-project', ScrollItem__BillboardProject);
