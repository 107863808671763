import { MeshBasicMaterial, TextureLoader, Vector3, PlaneGeometry, Mesh, DoubleSide } from 'three';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';

import { isMobile } from '../_app/cuchillo/core/Basics';

export const SETTINGS = {
    lightColor: '#cccccc',
    cameraPos: new Vector3(-1500, 100, 2500),
    cameraPosHome: isMobile ? new Vector3(-400, 0, 1500) : new Vector3(-600, 100, 2500),
    fov: 50,
    clearColor: '#171c2b',
    mouseX: 1.5,
    mouseY: 1.5,
    mouseLerp: .015,
    controls: false,
    cameraLerp: .08,
    groupRotation: false,
    cameraRotation: true,
    camera2D: false,
    letterSize: 180,
    zincrement: 2
}

export const SCRABBLE_MATERIAL = new MeshBasicMaterial({ color: 0xFFFBEF, transparent: true });
export let SCRABBLE_OBJ = [];

export const PLANE_MATERIAL = new MeshBasicMaterial({ color: 0xFFFBEF, transparent: true });
export const PLANE_GEOMETRY = new PlaneGeometry();

export const OBJ_LOADER = new OBJLoader();
export const TEXTURE_LOADER = new TextureLoader();
export const CENTER = new Vector3();