import gsap, { Power2, Power3 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import Default from './Default';
import Scene from '../3D/Scene';
import Word from '../3D/Word';
import { isTouch } from '../_app/cuchillo/core/Basics';

export default class Projects extends Default {
  totalSlidesDom;
  currentSlideDom;
  scrollbar;
  images = [];
  words = [];

  constructor() {
    super();

    Scene.init();

    this._call = () => this.raf();

    this.currentSlideDom = GetBy.selector('[scroll-slider-current]', this.container)[0];
    this.totalSlidesDom = GetBy.selector('[scroll-slider-total]', this.container)[0];
    this.scrollbar = new Scrollbar(GetBy.selector('.scrollbar .thumb', this.container)[0]);

    const images = GetBy.class('__image', this.container);
    for (let i = 0; i < images.length; i++) {
      const element = images[i];
      this.images.push(new Thumbnail(element, i));
    }
    this.totalSlidesDom.innerHTML = this.images.length;

    const words = GetBy.class('__word', this.container);
    for (let i = 0; i < words.length; i++) {
      const name = words[i];
      new SplitText(name, { type: "words", tag: 'div' }).words;
      const chars = new SplitText(name, { type: "chars", charsClass: 'letter __letter', tag: 'span' }).chars;
      this.words.push(new Word(name, chars));
    }
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Scene.start();
  }

  afterShow() {
    super.afterShow();
    gsap.ticker.add(this._call);

    gsap.to([GetBy.id('Token'), GetBy.id('Letterbag')], {
      opacity: 1,
      duration: .3,
      ease: Power2.easeOut,
      onComplete: () => {
        GetBy.id('Letterbag').style.pointerEvents = 'initial';
        GetBy.id('Token').style.pointerEvents = 'initial';
      }
    });

    this.images.map((image, i) => { image.show((this.images.length - 1 - i) * .15) });
    this.words.map((word, i) => { word.show(i * .15) });
  }

  beforeHide() {
    super.beforeHide();

    this.words.map((word, i) => { word.hide(i * .02) });
    gsap.to([GetBy.id('Token'), GetBy.id('Letterbag')], {
      opacity: 0,
      duration: .3,
      ease: Power2.easeOut,
      onComplete: () => {
        GetBy.id('Letterbag').style.pointerEvents = 'none';
        GetBy.id('Token').style.pointerEvents = 'none';
      }
    });
  }

  afterHide() {
    gsap.ticker.remove(this._call);
    this.words.map(word => { word.dispose() });
    Scene.stop();
    super.afterHide();
  }

  raf() {
    const p = Scroll.engine.progress;

    // DOM
    const current = Math.floor(Maths.lerp(0, this.images.length, p));
    this.currentSlideDom.innerHTML = current + 1;
    for (let i = 0; i < this.images.length; i++) {
      this.images[i].loop(current);
    }

    this.scrollbar.loop(p);

    // Three
    const threshold = 1 / this.words.length;
    for (let i = 0; i < this.words.length; i++) {
      const wordP = Maths.map(p, threshold * i, threshold * (i + 1), 0, 1);

      let opacity = 1;
      // if (!isTouch && wordP >= 1) opacity = Maths.clamp(Maths.map(wordP, 1, 1.8, 1, 0), 0, 1);

      let current = false;
      if (!isTouch && wordP < 1 && wordP >= 0) current = true;

      this.words[i].loop(opacity, current);
    }

    Scene.loop();
  }

  resize() {
    super.resize();
    Scene.resize();
    this.words.map(word => word.resize());
  }
}

ControllerPage._addPage('projects', Projects);

class Thumbnail {
  _dom;
  index;
  isShow = false;

  constructor(__d, __i) {
    this._dom = __d;
    this.index = __i;

    gsap.set(this._dom, { opacity: 0 });
  }

  loop(p) {
    if (!this.isShow) return;

    if (p === this.index) gsap.set(this._dom, { opacity: 1 });
    else gsap.set(this._dom, { opacity: 0 });
  }

  show(__d = 0) {
    if (this.isShow) return;

    gsap.killTweensOf(this._dom);
    gsap.to(this._dom, {
      duration: .3,
      delay: __d,
      opacity: 1,
      ease: Power3.easeOut,
      onComplete: () => {
        this.isShow = true;
      }
    });

  }

  hide(__d = 0) {
    if (!this.isShow) return;

    gsap.killTweensOf(this._dom);
    gsap.to(this._dom, {
      duration: .2,
      delay: __d,
      opacity: 0,
      ease: Power3.easeOut,
      onComplete: () => {
        this.isShow = false;
      }
    });
  }

  dispose() { }
}

class Scrollbar {
  _dom;
  _p = 0;

  constructor(__d, __i) {
    this._dom = __d;
  }

  loop(p) {
    const speed = (p - this._p) * 0.1;

    if (speed === 0) this._p = p;
    else this._p += speed;

    gsap.set(this._dom, { scaleY: this._p })
  }

  show() { }

  hide() { }

  dispose() { }
}
