import { Group, Raycaster, Vector2, Vector3 } from 'three';

import WebGLSketch from "../_app/cuchillo/3D/WebGLSketch";
import { Basics, isSmartphone } from '../_app/cuchillo/core/Basics';
import { Interaction } from '../_app/cuchillo/core/Interaction';

export default class ScrabbleSketch extends WebGLSketch {
    cameraPosition = new Vector3();
    group = new Group();
    mouse = new Vector2();
    raycaster = new Raycaster();
    interactiveObjects = [];

    constructor(opts = {}) {
        super(opts);

        this.scene.add(this.group);

        this.group.position.x = -100;
    }

    start() {
        super.start();
        this._raycast = (e) => this.raycast(e);
        document.addEventListener(Basics.clickEvent, this._raycast);
    }

    stop() {
        super.stop();
        this.removeEventListeners();
        document.removeEventListener(Basics.clickEvent, this._raycast);
    }

    handleInteractions() {
        let cursor = 'default';

        const mouse = {
            x: (Interaction.positions.mouse.x / window.innerWidth) * 2 - 1,
            y: -(Interaction.positions.mouse.y / window.innerHeight) * 2 + 1
        };

        this.raycaster.setFromCamera(mouse, this.camera);
        const intersects = this.raycaster.intersectObjects(this.interactiveObjects);

        if (intersects.length > 0) {
            intersects[0].object.parent.parent.word.forceHover()
            cursor = 'pointer';
        }

        document.body.style.cursor = cursor;
    }

    raycast(e) {
        e.preventDefault();

        const clientX = e.clientX;
        const clientY = e.clientY;

        const mouse = {
            x: (clientX / window.innerWidth) * 2 - 1,
            y: -(clientY / window.innerHeight) * 2 + 1
        };

        this.raycaster.setFromCamera(mouse, this.camera);
        const intersects = this.raycaster.intersectObjects(this.interactiveObjects);

        if (intersects.length > 0) {
            intersects[0].object.parent.parent.word.dom.click();
        }
    }

    loop() {
        this.handleInteractions();
        super.loop();
    }

    resize() {
        if (isSmartphone) return;

        this.domElement.style = "";
        const { width, height } = this.domElement.getBoundingClientRect();

        if (width === this.size.x && height === this.size.y) return;

        this.size.set(width, height);
        this.renderer.setSize(this.size.x, this.size.y);

        this.camera.aspect = this.size.x / this.size.y;
        this.camera.updateProjectionMatrix();
    }
}
